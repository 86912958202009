var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff-document-table"},[_c('base-data-table',{attrs:{"title":"Staff Documents","items":_vm.documents,"headers":_vm.headers,"loading":_vm.loading},on:{"create-item":function($event){return _vm.$emit('create-document')}},scopedSlots:_vm._u([{key:"item.createdUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdUtc))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit-document', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.$emit('download-document', item)}}},[_c('v-list-item-title',[_vm._v("Download")])],1),(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete-document', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }