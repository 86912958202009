

























import { UserDocumentModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import StaffDocumentTable from "@/components/staff/StaffDocumentTable.vue";
import StaffDocumentCreateDialog from "@/components/dialogs/StaffDocumentCreateDialog.vue";
import StaffDocumentUpdateDialog from "@/components/dialogs/StaffDocumentUpdateDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    StaffDocumentTable,
    StaffDocumentCreateDialog,
    StaffDocumentUpdateDialog
  }
})
export default class VesselDocuments extends Vue {
  @Prop(String) private userId!: string;
  private selectedUserDocumentId: string | undefined = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: UserDocumentModel) {
    if (!document.userId) {
      return;
    }

    this.selectedUserDocumentId = document.userDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: UserDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: UserDocumentModel) {
    if (!document.userDocumentId) {
      return;
    }

    try {
      await Api.UserDocumentService.apiUserdocumentUserDocumentIdDelete(
        document.userDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    } finally {
      this.refreshKey = !this.refreshKey;
    }
  }
}
